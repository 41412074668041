import React from "react"
import Layout from "../components/layout/layout"
import "./mystyles.scss"
import { Helmet } from "react-helmet"

const Card = ({ day, training_type, rdv, pic, depart }) => {
  return (
    <div className="box">
      <h1 className="title">{day}</h1>
      <h2 className="subtitle">{training_type}</h2>
      <p className="">Lieu de rendez-vous : {rdv}.</p>
      <p> Heure de départ: {depart} </p>
      <figure className="image is-5by3">
        <img
          styles={{ width: "20px", height: "20px" }}
          src={pic}
          alt="Placeholder"
        />
      </figure>
    </div>
  )
}

export default function Entrainement({ location }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Entraînements</title>
      </Helmet>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Les entrainements de course à pied</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <Card
                day="Lundi"
                training_type="entraînement fractionné"
                rdv="Stade Universitaire Saint-Léonard, Fribourg"
                depart="au alentour de 19h45"
                pic="https://www.realsport.ch/sites/default/files/styles/image_header_small/public/2018-01/fribourg_st-L%C3%A9onard.jpg?h=7685ba0d&itok=kxDLpSbF"
              />
            </div>
            <div className="column">
              <Card
                day="Jeudi"
                training_type="entraînement endurance"
                rdv="Parking d'Aldi, Villars-sur-Glâne"
                depart="au alentour de 19h00"
                pic="https://www.nosbalades.ch/wp-content/uploads/2017/06/IMG_6191.jpg"
              />
            </div>
            <div className="column">
              <Card
                day="Dimanche"
                training_type="entraînement endurance"
                rdv="Parking d'Aldi, Villars-sur-Glâne"
                depart="au alentour de 18h30"
                pic="https://www.nosbalades.ch/wp-content/uploads/2017/06/IMG_6191.jpg"
              />
            </div>
          </div>
          Les heures de départ des entraînements indiquées peuvent faire l'objet
          de modifications. Les horaires définitifs de chaque entraînements
          seront transmis par les entraîneurs.
        </div>
      </section>
    </Layout>
  )
}
